import type {Site} from '@data/types';
import {localizePathBySite} from '@/utils/site';

export const topicEnPath = (enBlogPath: string, topic: string) => {
  const topicHandle = topic
    .trim()
    .toLowerCase()
    .replace(
      /[^0-9_\-ー\p{Script=Hiragana}\p{Script=Katakana}\p{Script=Han}\p{Script=Hangul}\p{Script=Latin}\p{M}\s]/gu,
      '',
    )
    .replace(/\s+/g, '-')
    .replace(/-+/g, '-');

  return `${enBlogPath}topics/${topicHandle}`;
};

export const filterArticleTag = (tags: string[]) => {
  const filteredSystemTags = filterSystemArticleTag(tags);

  if (filteredSystemTags.length > 1) {
    const filteredBusinessTags = filteredSystemTags.filter(
      (tag) =>
        // NOTE: ['Business', 'Business Ideas', 'Start a Business'] tags where added additionally to articles
        // in the scope of https://github.com/Shopify/brochure-iii/issues/9612 as Experiment
        // So we temporary hide them from main breadcrumbs if other tags exists
        !['Business', 'Business Ideas', 'Start a Business'].includes(tag),
    );
    if (filteredBusinessTags.length) return filteredBusinessTags[0];
  }

  return filteredSystemTags[0];
};

const filterSystemArticleTag = (tags: string[]) => {
  return tags.filter(
    (tag) =>
      !['Hero', 'Featured', 'Resource', 'Most Read', 'Masters'].includes(tag) &&
      !tag?.startsWith('_') &&
      !tag?.includes('reviewer:'),
  );
};

export const getSearchUrlWithQuery = (
  searchValue: string,
  site: Site,
  enBlogPath: string,
) => {
  const trimSearchValue = searchValue.trim();

  return trimSearchValue.length > 0
    ? localizePathBySite(`${enBlogPath}search?q=${trimSearchValue}`, site)
    : null;
};

import type {AnchorHTMLAttributes} from 'react';

import type {LinkComponent} from '@/types';
import type {Mode} from '@/components/base/types';

export enum BLOG_PAGE_TYPE {
  BLOG = 'blog',
  RETAIL = 'retail',
  PARTNERS = 'partners',
  ENTERPRISE = 'enterprise',
  ENGINEERING = 'engineering',
}

export interface Article {
  id: string;
  title: string;
  tags: string[];
  authorV2?: ArticleAuthor;
  image?: {
    url: string;
    altText?: string;
  };
  seo?: {
    title?: string;
    description?: string;
  };
  excerpt?: string;
  contentHtml: string;
  imageUrl?: string | null; // this is added in blogsLoader
  imageAltText?: string | null; // this is added in blogsLoader
  imageClass?: string | null; // this is added in blogsLoader
  handle: string;
  createdAt?: string;
  createdAtRaw?: string; // non-formatted date
  modifiedAt: string;
  modifiedAtRaw: string; // non-formatted date
  publishedAt: string;
  metafields: ArticleMetafield[];
  topicHandle?: string;
  path?: string;
  disableTopicLink?: boolean;
}

export interface ArticleMetafield {
  key?: string;
  value?: string | Record<string, string>;
}

export interface ArticleAuthor {
  name: string;
  email?: string; // this is returned from Storefront but we remove it in article loader to prevent exposing client side
  avatarUrl?: string; // this is added in loader
  isDisabledAuthor?: boolean;
}

export interface Author {
  bio?: string;
  firstName: string;
  lastName: string;
  email: string;
  name: string;
  avatarUrl?: string; // added in loader
}
export interface Topic {
  image?: string;
  title: string;
  description: string;
  handle: string;
  subTitle?: string;
  tag?: string;
}

export interface PageInfo {
  endCursor?: string;
  hasNextPage: boolean;
  hasPreviousPage: boolean;
  nextPageHref?: string; // this is added in blogsLoader
  previousPageHref?: string; // this is added in blogsLoader
  startCursor?: string;
}

export type NumberedPagesPageInfo = Pick<
  PageInfo,
  'hasNextPage' | 'hasPreviousPage'
> & {
  hasThirdPage: boolean;
};

export interface GroupedArticlesListProps {
  primaryArticles: Article[];
  enBlogPath: string;
  blogType?: BLOG_PAGE_TYPE;
  sectionHead?: {
    heading: string;
    link?: {
      text: string;
    };
  };
}

export interface BlogSectionProps
  extends AnchorHTMLAttributes<HTMLAnchorElement> {
  heading?: string;
  headingTag?: 'h3' | 'h4' | 'h5' | 'h6';
  link?: LinkComponent;
  className?: string;
  containerStyles?: string;
}

export type BlogCardClassNames = {
  tag?: string;
  title?: string;
  description?: string;
  author?: string;
  date?: string;
  image?: string;
  cardBorder?: string;
};

export interface BlogCardProps {
  article: Article;
  enBlogPath: string;
  type?: 'hero' | 'grid-3' | 'grid-4' | 'noImage';
  className?: string;
  preferredTag?: string;
  blogType?: BLOG_PAGE_TYPE;
  translationNamespace?: string;
  target?: string;
  mode?: Mode;
}

export interface ContentNavigationLink {
  href: string;
  text: string;
}

export interface ContentNavigationLink {
  href: string;
  text: string;
}

export interface Settings {
  topSectionImages: {src: string}[];
  subscription: {settings: {subscriptionId: string}};
  images: any;
  popularArticles: any;
}

export type PopularArticle = {
  title: string;
  tags: string[];
  handle: string;
  imageUrl: string | null;
  imageAltText: string | null;
  modifiedAt: string;
};

export type AuthorData = {
  name?: string;
  slug?: string;
  prefix?: string;
  isDisabledAuthor?: boolean;
};

export interface PageCluster {
  [key: string]: string;
}

import * as React from 'react';
import {Form} from '@remix-run/react';
import {SearchIcon, XIcon} from '@shopify/polaris-icons';
import cn from 'classnames';

import {useSiteData} from '@/hooks/useSiteData';
import {useKeyPress} from '@/hooks/useKeyPress';
import {BLOG_PAGE_TYPE} from '@/components/blog/types';
import {HeaderBackgroundColorScheme} from '@/enums';
import {twMerge} from '@/stylesheets/twMerge';

import {getSearchUrlWithQuery} from '../utils';

interface NavigationSearchProps {
  searchText: string;
  searchPlaceholder: string;
  searchPlaceholderMobile: string;
  enBlogPath: string;
  blogType?: BLOG_PAGE_TYPE;
  theme?:
    | HeaderBackgroundColorScheme.TransparentDark
    | HeaderBackgroundColorScheme.TransparentLight;
  inputWrapperClassName?: string;
}

export const NavigationSearch = ({
  searchText,
  searchPlaceholder,
  searchPlaceholderMobile,
  enBlogPath,
  blogType,
  theme,
  inputWrapperClassName,
}: NavigationSearchProps) => {
  const site = useSiteData().site;
  const inputRef = React.useRef<HTMLInputElement | null>(null);
  const [isSearchOpen, setIsSearchOpen] = React.useState(false);
  const [searchValue, setSearchValue] = React.useState('');
  const isEnterpriseBlog = blogType === BLOG_PAGE_TYPE.ENTERPRISE;
  const isDark =
    theme === HeaderBackgroundColorScheme.TransparentDark || isEnterpriseBlog;

  React.useEffect(() => {
    if (isSearchOpen) {
      inputRef.current?.focus();
    }
  }, [isSearchOpen]);

  useKeyPress('Escape', () => setIsSearchOpen(false));

  const handleSubmit = React.useCallback(
    (event: React.FormEvent<HTMLFormElement>) => {
      event.preventDefault();

      const searchUrlWithQuery = getSearchUrlWithQuery(
        searchValue,
        site,
        enBlogPath,
      );
      searchUrlWithQuery && window.location.assign(searchUrlWithQuery);
    },
    [enBlogPath, searchValue, site],
  );

  return (
    <>
      <div
        className="hidden lg:flex flex-row items-center text-base cursor-pointer blog-navbar-navigation"
        onClick={() => {
          setIsSearchOpen(true);
        }}
      >
        {searchText}
        <SearchIcon
          className={cn({'[&>path]:fill-white': isDark}, 'w-4 h-4 ml-2')}
        />
      </div>
      <div
        className={twMerge(
          cn(
            'mb-6 lg:mb-0 lg:absolute lg:bottom-0 lg:left-0 w-full bg-white border-b border-black/20 z-10',
            {'lg:hidden': !isSearchOpen},
            {
              'lg:!bg-enginneering-dark lg:!bg-engineering-dark-bg max-lg:!bg-transparent border-white/20':
                isDark,
            },
          ),
          inputWrapperClassName,
        )}
      >
        <div className="lg:container lg:h-[72px] flex items-center">
          <div className="flex flex-row items-center text-base relative w-full border-b border-[#ccc]">
            <Form
              className="flex lg:block w-full -mt-4 lg:mt-0"
              onSubmit={(event) => {
                handleSubmit(event);
              }}
              noValidate
              data-component-extra-search-value={searchValue}
            >
              <input
                className={cn(
                  'outline-0 text-base relative z-20 bg-transparent w-full lg:placeholder:text-transparent py-2',
                  {
                    'placeholder-shade-50': !isDark,
                    'text-white placeholder-white': isDark,
                  },
                )}
                type="text"
                name="header-search"
                autoComplete="off"
                ref={inputRef}
                value={searchValue}
                placeholder={searchPlaceholderMobile}
                onChange={(event) => {
                  setSearchValue(event.target.value);
                }}
              />
              <button className="lg:hidden p-6 -mr-6" aria-label="Search icon">
                <SearchIcon
                  className={cn(
                    {'[&>path]:fill-white': isDark},
                    'w-4 h-4 ml-2',
                  )}
                />
              </button>
            </Form>
            {searchValue.length === 0 && (
              <div
                className={cn(
                  'hidden lg:flex absolute z-10 top-1/2 left-0 -translate-y-1/2',
                  'flex-row items-center text-base',
                  {
                    'text-shade-50': !isDark,
                    'text-white': isDark,
                  },
                )}
              >
                {searchPlaceholder}
                <SearchIcon
                  className={cn(
                    {'[&>path]:fill-white': isDark},
                    'w-4 h-4 ml-2',
                  )}
                />
              </div>
            )}
            <div
              className="hidden lg:block p-2 cursor-pointer ml-auto"
              onClick={() => {
                setIsSearchOpen(false);
                setSearchValue('');
              }}
            >
              <XIcon
                className={cn('size-5', {'[&>path]:fill-white': isDark})}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
